@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

html {
  font-size: 100%;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.25em;
}

h4 {
  font-size: 1.125em;
  font-weight: 500;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 1em;
}

body, p {
  font-size: 1em;
  line-height: 1.5;
}

a {
  text-decoration: none;
}

.link-button-container {
  padding: 10px 0;
  margin-top: 20px;
}

.link-button {
  background-color: rgba(0, 123, 255, 1);
  border-radius: 10px;
  border: none;
  padding: 15px 30px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: black;
    color: white;
  }
}


.banner {
  height: 15px;
  background-color: #262626;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9em;
  text-align: right;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 10px;

    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.navbar {
  height: 60px;
  background-color: #1f1f1f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 20px;

  .logo-container, .logo {
    height: auto;
    max-width: 60px;
    min-width: 40px;
  }

  .hamburger {
    display: none;
    cursor: pointer;
    position: absolute;
    z-index: 999;

    .line {
      width: 30px;
      height: 3px;
      background-color: white;
      margin: 5px 0;
      transition: transform 0.3s ease, opacity 0.2s ease;
    }

    &.open .line:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }

    &.open .line:nth-child(2) {
      transform: rotate(-45deg) translate(7px, -6px);
    }

    @media (max-width: 768px) {
      display: block;
    }
  }

  .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    a {
      margin: 0 30px;
      color: white;
      text-decoration: none;
      font-size: 1em;

      &:hover {
        color: #dddddd;
      }
      &:visited {
        color: white;
      }
    }
  }

  .nav-item {
    position: relative;
    display: inline-block;

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;

      a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }

    &:hover .dropdown-content {
      display: block;
    }
  }

  .invest-button-container {
    padding: 10px 0;
  }

  .invest-button {
    background-color: rgba(0, 123, 255, 1);
    border-radius: 10px;
    border: none;
    padding: 15px 30px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: white;
      color: black;
    }
  }

  .mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #333;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;

    &.open {
      display: flex;
    }

    a {
      color: white;
      padding: 10px;
      text-decoration: none;

      &:hover {
        color: #ddd;
      }
    }
  }

  @media (max-width: 768px) {
    .nav-links, .invest-button-container {
      display: none;
    }

    .hamburger {
      display: block;
    }

    .nav-links.open, .invest-button-container.open {
      display: block;
      flex-direction: column;
    }
  }
}

.hero {
  position: relative;
  height: 100vh; //calc(100vh - 115px); // Adjust the height as needed
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;

  .hero-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }

  .hero-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white; // Adjust the color as needed
    z-index: 1;

    h1, h2 {
      color: #fff; // Ensuring readability against potentially dark backgrounds
    }

    h1 {
      margin-bottom: 0.5em; // Space between h1 and h2
      font-weight: 400;
    }

    h2 {
      font-weight: 200;
      margin-bottom: 20px;
    }
  }

  .icon-scroll {
    width: 20px;
    height: 35px;
    box-shadow: inset 0 0 0 1px #fff;
    border-radius: 25px;
    position: relative;
    margin-top: 20px; // Space above the icon

    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background: #fff;
      left: 50%;
      margin-left: -4px;
      top: 8px;
      border-radius: 4px;
      animation: scroll 1.5s infinite;
    }
  }

  #mc_embed_signup {
    background: rgba(255, 255, 255, 0.8); // Semi-transparent background for form
    padding: 20px;
    border-radius: 8px;
    margin-top: 30px; // Space between content and form
    width: auto; // Adjust based on your preference
    min-width: 200px; // Ensure the form doesn't stretch too wide

    input[type="email"], input[type="text"] {
      width: calc(100% - 20px); // Full width minus padding
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 4px;
      border: 1px solid #ccc; // Subtle border
    }

    input[type="submit"] {
      background-color: #007bff; // Button color
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken(#007bff, 10%); // Darker on hover
      }
    }

    .mc-field-group {
      margin-bottom: 15px;
    }

    label {
      display: block;
      margin-bottom: 5px;
      color: #333; // Dark color for contrast
    }

    .indicates-required {
      margin-bottom: 20px;
    }

    .response {
      margin-top: 10px;
    }
  }

  @keyframes scroll {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(23px);
    }
  }
}


.sub-hero {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  padding: 3rem 1.5rem;

  .sub-hero-logo {
    margin-bottom: 1rem;
    // Add more styles if necessary
  }

  .sub-hero-content {
    margin: auto;
    max-width: 600px; // Adjust as needed

  }
}

.feature {
  display: grid;
  grid-template-columns: 1fr 1fr; // Two columns of equal width
  gap: 20px; // Space between columns
  margin: 40px 0; // Vertical spacing from other elements
  padding: 20px;
  min-height: 800px;

  &.image-left {
    grid-template-areas: "image-section text-section";
  }

  &.image-right {
    grid-template-areas: "text-section image-section";
  }

  .text-section {
    grid-area: text-section;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
      text-transform: uppercase;
      font-size: medium;
    }

    h3 {
      font-size: x-large;
    }
  }

  .image-section {
    grid-area: image-section;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  @media (max-width: 768px) {
    &.feature {
      grid-template-columns: 1fr;
      grid-template-areas:
        "image-section"
        "text-section";
    }
  }
}


.subscribe-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f2f2f2;
  margin: 20px 0;

  span {
    margin-right: 10px;
    font-weight: bold;
  }

  input[type="email"] {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-right: 10px;

    &:focus {
      outline: none;
      border-color: #3498db;
    }
  }

  button {
    background-color: rgba(0, 123, 255, 0.7);
    border-radius: 20px;
    border: none;
    padding: 5px 20px;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: white;
      color: black;
    }
  }
}

.footer {
  background-color: #333;
  color: white;
  padding: 20px;
  font-size: 0.9em;

  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 20px;

    .footer-logo {
      width: 50%;

      img {
        width: 64px; // Adjust as necessary
        height: auto;
      }

      p {
        margin-top: 10px;
      }
    }

    .footer-links {
      display: flex;

      .footer-column {
        margin-left: 20px;

        h4 {
          margin-bottom: 10px;
        }

        // Style your link elements here
      }
    }
  }

  .footer-bottom {
    border-top: 1px solid #555;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column; // Change to row for larger screens if desired

    a {
      color: white;
      text-decoration: none;
      margin: 5px 0;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      margin-top: 10px;
    }
  }
}

.benefits {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;

  .benefits-content {
    flex-basis: 50%;
    padding: 20px;

    ul {
      // Styles for bullet point list
      li {
        text-align: left;
        // Styles for list item
      }
    }
  }

  .benefits-image {
    flex-basis: 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 300px; // Adjust as needed
  }
}

.tagline {
  background-size: cover;
  background-position: center;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px; // Adjust the height as needed

  .tagline-text {
    color: white; // Adjust text color as needed
    font-size: 3rem; // Large text size
    max-width: 80%; // Control the max width of the tagline
    margin: auto; // Centering the text
  }
}

.process {
  background-size: cover;
  background-position: center;
  text-align: center;
  position: relative;
  min-height: 400px; // Adjust as needed
  display: flex;
  justify-content: center;
  align-items: center;

  .process-content {
    color: black; // Adjust as needed
    padding: 20px;

    ol {
      li {
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.3s ease, transform 0.3s ease;

        &.animate {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}

.pricing {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  text-align: center;

  .request-demo-btn {
    padding: 10px 20px;
    background-color: #007bff; // Example button color, adjust as needed
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3; // Darken the button on hover, adjust as needed
    }
  }
}

.how-it-works {
  background-size: cover;
  background-position: center;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 800px;

  .content {
    max-width: 600px;

    h1 {
      margin-bottom: 20px;
      font-size: 2em;
    }

    h2 {
      margin-bottom: 15px;
      font-size: 1.5em;
      text-transform: uppercase;
    }

    p {
      font-size: 1em;
      text-align: left;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 10px;

    .content {
      h1 {
        font-size: 1.75em;
      }

      h2 {
        font-size: 1.25em;
      }

      p {
        font-size: 0.9em;
      }
    }
  }
}

.login-form-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 10px;
  background-color: white;
}

.login-form .form-group {
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 5px;
  }

  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 5px 0 20px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.remember-me {
  display: flex;
  align-items: center;
}

.login-button {
  width: 100%;
  background-color: #4C7EF3;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.signup-link {
  text-align: center;
  margin-top: 20px;

  a {
    color: #4C7EF3;
  }
}

//Below are the styling for any new changes/modifications to the site

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .icon-container {
    // Add styles for icon container
    margin-bottom: 10px;

    img {
      width: 50px; // Adjust as needed
      height: auto;
    }
  }

  .text-container {
    // Add styles for text container

    h3 {
      margin-bottom: 5px;
      font-size: 1.2em;
    }

    p {
      font-size: 1em;
    }
  }
}
